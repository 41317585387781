import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import Fuse, { IFuseOptions } from 'fuse.js';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { sortProjectsByEndDate } from 'src/app/core/utils';
import type { ParsedContractorProject, Project, ProjectOwnerFilterOptions } from 'src/types';
import { CreateProjectDialogComponent } from '../create-project-dialog/create-project-dialog.component';

const fuseOptions: IFuseOptions<Project> = {
  threshold: 0.3,
  keys: ['project_name', 'project_details.location.city', 'project_details.location.state'],
};

@Component({
  selector: 'app-contractor-dashboard',
  templateUrl: './contractor-dashboard.component.html',
  styleUrls: ['./contractor-dashboard.component.scss'],
})
export class ContractorDashboardComponent implements OnInit {
  displayedColumns: string[] = ['id', 'project_name', 'location', 'project_owner'];
  dataSource = new MatTableDataSource<ParsedContractorProject>();
  projects: Array<Project> = [];
  filterForm = new FormGroup({
    search: new FormControl(''),
    location: new FormControl('all'),
  });

  projectOwnerFilterOptions: ProjectOwnerFilterOptions;
  fuse: Fuse<Project>;
  projectLocationFilterOptions: Array<String>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private amcProjectService: AmcProjectService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.filterForm.valueChanges.subscribe(() => this._filterSearch());
    this.fuse = new Fuse(this.amcProjectService.projects, fuseOptions);
  }

  ngOnInit(): void {
    Promise.all([
      this.amcProjectService.fetchProjects(),
      this.amcProjectService.fetchContractors(),
    ]).then(() => {
      this.refreshProjectData();
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  refreshProjectData() {
    this.setProjectOwnerFilterOptions();
    this.setProjectLocationFilterOptions();
    this._filterSearch();
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, {
      width: '900px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { mode: 'create' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.amcProjectService.fetchProjects().then(() => {
          this.refreshProjectData();
        });
      }
    });
  }

  _filterSearch() {
    const { search: searchQuery, location } = this.filterForm.value;

    let result: Array<Project>;

    if (!searchQuery || searchQuery === '') {
      result = this.amcProjectService.getProjects();
    } else {
      result = this.fuse.search(searchQuery).map(({ item }) => item);
    }

    if (location !== 'all') {
      if (location === 'N/A') {
        result = result.filter(
          item => !item.project_details.location.city && !item.project_details.location.state
        );
      } else {
        result = result.filter(
          item =>
            location ===
            `${item.project_details.location.city}, ${item.project_details.location.state}`
        );
      }
    }

    this.projects = result.sort(sortProjectsByEndDate);

    this.updateTableData();
  }

  get status() {
    return this.filterForm.get('status');
  }

  get contractors() {
    return this.amcProjectService.contractors;
  }

  updateTableData() {
    const projectInView = this.getProjectsInView();
    this.dataSource = new MatTableDataSource<ParsedContractorProject>(projectInView);
    this.dataSource.paginator = this.paginator;
  }

  getProjectsInView() {
    return this.projects.map(item => {
      return {
        id: item.id,
        projectName: item.project_name,
        business: item.organization.name,
        location:
          !item.project_details.location.city && !item.project_details.location.state
            ? 'N/A'
            : `${item.project_details.location.city}, ${item.project_details.location.state}`,
      };
    });
  }

  setProjectOwnerFilterOptions() {
    this.projectOwnerFilterOptions = [
      ...new Set(this.amcProjectService.projects.map(({ project_owner }) => project_owner)),
    ].map(item => {
      return {
        name: item,
        value: item.toLowerCase().split(' ').join('-'),
      };
    });
  }

  setProjectLocationFilterOptions() {
    this.projectLocationFilterOptions = [
      ...new Set(
        this.amcProjectService.projects.map(({ project_details }) =>
          !project_details.location.city && !project_details.location.state
            ? 'N/A'
            : `${project_details.location.city}, ${project_details.location.state}`
        )
      ),
    ];
  }

  openAmcDetails(amcId: string) {
    this.router.navigate(['/contractor/' + amcId]);
  }
}
