<div mat-dialog-content class="p-5">
  <h1 class="text-xl font-medium text-secondary">Mark Activity as Completed?</h1>
  <p class="mt-3">
    Are you sure you want to mark this activity as completed? This action cannot be undone. You can
    fill the report and then mark the activity as completed, or skip it.
  </p>
  <div class="mt-4 flex justify-end">
    <button type="button" (click)="cancel()" class="cancel-btn">Cancel</button>
    <button class="fill-report-btn" (click)="fillReport()" [disabled]="isLoading">
      Fill Report
    </button>
    <button class="mark-as-completed-btn" (click)="skipAndMarkAsCompleted()" [disabled]="isLoading">
      Skip and Mark as Completed
    </button>
  </div>
</div>
