import { Component, Inject, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';

interface ChecklistItem {
  desc: string;
  status: boolean;
}

interface ChecklistSection {
  section: string;
  checklist: ChecklistItem[];
}

@Component({
  selector: 'app-fill-activity-report-dialog',
  templateUrl: './fill-activity-report-dialog.component.html',
  styleUrl: './fill-activity-report-dialog.component.scss',
})
export class FillActivityReportDialogComponent implements OnInit {
  private _snackBar = inject(MatSnackBar);
  reportForm: FormGroup;
  mediaPreviews: Array<{ src: string; type: string }> = [];
  acceptedFileTypes: Array<string> = ['image/jpeg', 'image/png', 'video/mp4'];
  activityChecklist = {
    pvChecklist: {
      section: 'PV Checklist',
      checklist: [
        { desc: 'Structure, pedestal, mid/end clamp is missing/damaged', status: false },
        { desc: 'Rust on the structure', status: false },
        { desc: 'PV modules are damaged', status: false },
        { desc: 'Modules are clean', status: false },
        { desc: 'Hotspot, snail trail, shadow over the panels', status: false },
        { desc: 'LA connection and earthing are OK', status: false },
      ],
    },
    inverterChecklist: {
      section: 'Inverter Checklist',
      checklist: [
        { desc: 'All inverters are working', status: false },
        { desc: 'String connections are OK', status: false },
        { desc: 'Noted string currents and voltages', status: false },
        { desc: 'AC and DC connectors tightened', status: false },
        { desc: 'Inverter earthing is OK', status: false },
        { desc: 'Inverter fans are clean and working properly', status: false },
      ],
    },
    acdbdcdb: {
      section: 'ACDB/DCDB',
      checklist: [
        { desc: 'Any cable/terminal/connection getting heated', status: false },
        { desc: 'Busbar tightness is OK', status: false },
        { desc: 'MCB, MCCB, ACB terminal tightness are OK', status: false },
        { desc: 'All energy meters working properly', status: false },
        { desc: 'Earthing of LT panel/ACDB is OK', status: false },
        { desc: 'SPD is OK', status: false },
      ],
    },
    monitoringDevice: {
      section: 'Monitoring Device',
      checklist: [
        { desc: 'Power status - Device is ON', status: false },
        { desc: 'Network status - Device is Online', status: false },
        { desc: 'Device is working properly', status: false },
        { desc: 'LAN cable connections on inverter and RMS end are OK', status: false },
      ],
    },
    plumbing: {
      section: 'Plumbing',
      checklist: [
        { desc: 'Water conduit is OK', status: false },
        { desc: 'Motor is functioning properly', status: false },
      ],
    },
  };
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FillActivityReportDialogComponent>,
    private fb: FormBuilder,
    private amcProjectService: AmcProjectService,
    @Inject(MAT_DIALOG_DATA) public data: { activityId: string }
  ) {}

  ngOnInit(): void {
    this.reportForm = new FormGroup({
      media: new FormControl<Array<File>>([]),
      activityChecklist: this.fb.group({
        pvChecklist: this.createChecklistGroup(this.activityChecklist.pvChecklist.checklist),
        inverterChecklist: this.createChecklistGroup(
          this.activityChecklist.inverterChecklist.checklist
        ),
        acdbdcdb: this.createChecklistGroup(this.activityChecklist.acdbdcdb.checklist),
        monitoringDevice: this.createChecklistGroup(
          this.activityChecklist.monitoringDevice.checklist
        ),
        plumbing: this.createChecklistGroup(this.activityChecklist.plumbing.checklist),
      }),
      business_remarks: new FormControl<string>('', [Validators.required]),
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  onMediaPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.handleFile(file);
    }
  }

  handleFile(file: File) {
    // validate file type and size
    if (!this.acceptedFileTypes.includes(file.type)) {
      this.openSnackBar('Invalid File Type', 'OK');
      return;
    }
    const maxSize = 10 * 1024 * 1024; //10 MB (10 * 1024 * 1024 bytes)
    if (file.size > maxSize) {
      this.openSnackBar('File size exceeded!', 'OK');
      return;
    }

    // update media-preview and form-data
    const reader = new FileReader();
    reader.onload = e => {
      const mediaType = file.type.startsWith('image') ? 'image' : 'video';
      this.mediaPreviews.push({ src: reader.result as string, type: mediaType });
    };
    reader.readAsDataURL(file);

    const currentFiles = this.reportForm.get('media')?.value || [];
    this.reportForm.patchValue({ media: [...currentFiles, file] });
  }

  handleRemove(index: number) {
    // remove from media-preview
    this.mediaPreviews.splice(index, 1);

    // remove from form-data
    const currentFiles = this.reportForm.get('media')?.value || [];
    currentFiles.splice(index, 1);
    this.reportForm.patchValue({ media: [...currentFiles] });
  }

  createChecklistGroup(checklist: ChecklistItem[]) {
    return this.fb.array(checklist.map(item => new FormControl(item.status)));
  }

  getChecklistControls(section: string) {
    return (this.reportForm.get(['activityChecklist', section]) as FormArray).controls;
  }

  getSectionName(section: string) {
    const keyTyped = section as keyof typeof this.activityChecklist;
    return this.activityChecklist[keyTyped].section;
  }

  getDescriptionLabel(section: string, index: number) {
    const keyTyped = section as keyof typeof this.activityChecklist;
    return this.activityChecklist[keyTyped].checklist[index].desc;
  }

  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    this.reportForm.value.media.forEach((file: File) => {
      formData.append('media_files', file);
    });
    formData.append('activity_id', this.data.activityId);
    this.amcProjectService
      .uploadFilesforActivity(formData)
      .then(() => {})
      .catch(error => {})
      .finally(() => {
        const data = {
          extra_details: {
            activityChecklist: {
              pvChecklist: {
                section: this.activityChecklist.pvChecklist.section,
                checklist: this.activityChecklist.pvChecklist.checklist.map((item, index) => ({
                  desc: item.desc,
                  status: this.reportForm.value.activityChecklist.pvChecklist[index],
                })),
              },
              inverterChecklist: {
                section: this.activityChecklist.inverterChecklist.section,
                checklist: this.activityChecklist.inverterChecklist.checklist.map(
                  (item, index) => ({
                    desc: item.desc,
                    status: this.reportForm.value.activityChecklist.inverterChecklist[index],
                  })
                ),
              },
              acdbdcdb: {
                section: this.activityChecklist.acdbdcdb.section,
                checklist: this.activityChecklist.acdbdcdb.checklist.map((item, index) => ({
                  desc: item.desc,
                  status: this.reportForm.value.activityChecklist.acdbdcdb[index],
                })),
              },
              monitoringDevice: {
                section: this.activityChecklist.monitoringDevice.section,
                checklist: this.activityChecklist.monitoringDevice.checklist.map((item, index) => ({
                  desc: item.desc,
                  status: this.reportForm.value.activityChecklist.monitoringDevice[index],
                })),
              },
              plumbing: {
                section: this.activityChecklist.plumbing.section,
                checklist: this.activityChecklist.plumbing.checklist.map((item, index) => ({
                  desc: item.desc,
                  status: this.reportForm.value.activityChecklist.plumbing[index],
                })),
              },
            },
          },
          notes: {
            business_remarks: this.reportForm.value.business_remarks,
          },
          status: 'Completed',
        };
        this.amcProjectService
          .editActivity(this.data.activityId, data)
          .then(() => {
            this.dialogRef.close({ success: true });
          })
          .catch(error => {
            this.dialogRef.close({ success: true });
          });
        this.loading = false;
        this.dialogRef.close({ success: true });
      });
  }
}
