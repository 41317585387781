import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AMC_ROLE_TYPE } from 'src/app/core/constants';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Component({
  selector: 'app-edit-org-user-dialog',
  templateUrl: './edit-org-user-dialog.component.html',
  styleUrls: ['./edit-org-user-dialog.component.scss'],
})
export class EditOrgUserDialogComponent implements OnInit {
  AmcRoles = AMC_ROLE_TYPE;
  name = '';
  phoneNumber = '';
  email = '';
  editOrgForm = new FormGroup({
    role_type: new FormControl('', Validators.required),
  });
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<EditOrgUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ordId: number },
    private userService: UserProfileService
  ) {}

  ngOnInit(): void {
    const data = this.userService.getOrgUser(this.data.ordId);
    if (data?.name) this.name = data.name;
    if (data?.phone_number) this.phoneNumber = data.phone_number;
    if (data?.email) this.email = data.email;
    if (data?.role_type) this.editOrgForm.controls.role_type.setValue(data.role_type);
  }

  onSubmit() {
    const { role_type } = this.editOrgForm.value;
    if (role_type) {
      this.isLoading = true;
      this.userService
        .editOrgUserRole(this.data.ordId, role_type)
        .then(() => {
          this.isLoading = false;
          this.dialogRef.close({ success: true });
        })
        .catch(() => (this.isLoading = false));
    }
  }

  onDelete() {}
}
