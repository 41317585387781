<mat-dialog-content>
  <form class="container" [formGroup]="reportForm" (ngSubmit)="onSubmit()">
    <h1 class="title">Activity Report</h1>
    <div class="file-upload-section" appDnd (fileDropped)="handleFile($event)">
      <div class="icon-wrapper">
        <mat-icon class="icon">upload</mat-icon>
      </div>
      <div>
        <span>Choose a file or drag & drop it here</span>
        <span>JPEG, PNG, and MP4 formats, up to 10MB</span>
      </div>
      <label for="file-upload" class="upload-btn">Upload</label>
      <input
        id="file-upload"
        type="file"
        #filePicker
        (change)="onMediaPicked($event)"
        accept=".jpg, .jpeg, .png, .mp4"
      />
    </div>
    <div class="media-section">
      <h2 class="sub-title">Uploaded Media</h2>
      <div class="box">
        <span *ngIf="mediaPreviews.length == 0; else media_content">No Media Uploaded</span>
        <ng-template #media_content>
          <div class="media-container">
            <ng-container *ngFor="let media of mediaPreviews; let i = index">
              <div class="file-wrapper">
                <mat-icon class="close-icon" (click)="handleRemove(i)">close</mat-icon>
                <img
                  *ngIf="media.type == 'image'; else video"
                  [src]="media.src"
                  [alt]="'Image ' + i + 1"
                />
                <ng-template #video>
                  <video type="video/mp4" controls>
                    <source [src]="media.src" />
                  </video>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="observations-section">
      <h2 class="sub-title">Site Observations</h2>
      <div class="box">
        <div
          *ngFor="
            let section of [
              'pvChecklist',
              'inverterChecklist',
              'acdbdcdb',
              'monitoringDevice',
              'plumbing',
            ]
          "
          class="item"
        >
          <h3>{{ getSectionName(section) }}</h3>
          <div formArrayName="activityChecklist">
            <div [formArrayName]="section" class="inputs-container">
              <div
                *ngFor="let control of getChecklistControls(section); let i = index"
                class="input"
              >
                <span>{{ getDescriptionLabel(section, i) }}</span>
                <mat-slide-toggle [formControlName]="i" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="remarks-section">
      <h2 class="sub-title">Your Remarks</h2>
      <textarea type="text" rows="5" formControlName="business_remarks"> </textarea>
    </div>
    <div class="btn-section">
      <button type="button" (click)="cancel()">Cancel</button>
      <button class="complete-btn" type="submit" [disabled]="reportForm.invalid || loading">
        Mark as Completed <mat-icon>check</mat-icon>
      </button>
    </div>
  </form>
</mat-dialog-content>
