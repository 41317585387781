import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AMC_ACTIVITY_STATUS } from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { EditActivityData } from 'src/types';
import { FillActivityReportDialogComponent } from '../fill-activity-report-dialog/fill-activity-report-dialog.component';

@Component({
  selector: 'app-mark-as-completed-dialog',
  standalone: true,
  imports: [],
  templateUrl: './mark-as-completed-dialog.component.html',
  styleUrl: './mark-as-completed-dialog.component.scss',
})
export class MarkAsCompletedDialogComponent {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<MarkAsCompletedDialogComponent>,
    public fillReportDialogRef: MatDialogRef<FillActivityReportDialogComponent>,
    private amcProjectService: AmcProjectService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { activityId: string }
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }

  fillReport() {
    const dialogRef = this.dialog.open(FillActivityReportDialogComponent, {
      width: '800px',
      height: '90vh',
      data: {
        activityId: this.data.activityId,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.dialogRef.close({ success: true });
      }
    });
  }

  skipAndMarkAsCompleted(): void {
    this.isLoading = true;
    const data: EditActivityData = {
      status: AMC_ACTIVITY_STATUS.COMPLETED,
    };
    this.amcProjectService
      .editActivity(this.data.activityId, data)
      .then(res => {
        if (res.success) {
          this.isLoading = false;
          this.dialogRef.close({ success: true });
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
