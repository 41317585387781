import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '../auth/auth.service';
import { AUTH_TOKEN_KEY } from '../constants';
import { AmcProjectService } from './amc-project.service';
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  navbar: boolean = false;

  constructor(
    private localStorage: LocalStorageService,
    private userProfile: UserProfileService,
    private authService: AuthService,
    private amcProjectService: AmcProjectService
  ) {}

  showNavbar(value: boolean) {
    this.navbar = value;
  }

  initialize() {
    this.authService.authToken = this.localStorage.retrieve(AUTH_TOKEN_KEY);
    if (this.authService.authToken) {
      return this.userProfile
        .getUserProfile()
        .then(async resp => {
          this.userProfile.setUserProfile(resp);

          try {
            if (this.userProfile.isOrgApproved()) {
              if (this.userProfile.user.role_type != 'Workman') {
                await Promise.all([
                  this.userProfile.fetchOrgUsers(),
                  this.amcProjectService.fetchProjects(),
                  this.amcProjectService.fetchContractors(),
                ]);
              }
            }
          } catch (e) {
            // No need to logOut if this request fails
          }
        })
        .catch(error => {
          this.authService.logOut();
          return Promise.reject(error.status);
        });
    } else {
      return new Promise((resolve, reject) => resolve(true));
    }
  }
}
