import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AMC_ACTIVITY_TYPE } from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { formatAmcDate } from 'src/app/core/utils';

@Component({
  selector: 'app-create-activity-dialog',
  templateUrl: './create-activity-dialog.component.html',
  styleUrls: ['./create-activity-dialog.component.scss'],
})
export class CreateActivityDialogComponent {
  amcActivityType = AMC_ACTIVITY_TYPE.slice(0, -2);

  scheduleActivityForm_1: FormGroup = new FormGroup({
    site_name: new FormControl('', Validators.required),
    start_date: new FormControl<Date | null>(null, Validators.required),
    end_date: new FormControl<Date | null>(null, Validators.required),
  });

  scheduleActivityForm_2: FormGroup = new FormGroup({
    activities: new FormArray([
      new FormGroup({
        activity_type: new FormControl('', Validators.required),
        contractor_org: new FormControl(null, Validators.required),
        scheduled_date: new FormControl<Date | null>(null, Validators.required),
      }),
    ]),
  });

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CreateActivityDialogComponent>,
    private amcProjectService: AmcProjectService,
    @Inject(MAT_DIALOG_DATA) public data: { amcId: string }
  ) {}

  get activities() {
    return this.scheduleActivityForm_2.controls['activities'] as FormArray;
  }

  get contractors() {
    return this.amcProjectService.contractors;
  }

  createActivity() {
    this.isLoading = true;
    this.amcProjectService
      .createActivity(
        this.data.amcId,
        this.scheduleActivityForm_2.value.activities.map((i: any) => {
          return {
            ...i,
            scheduled_date: formatAmcDate(i.scheduled_date),
          };
        })
      )
      .then(() => {
        this.isLoading = false;
        this.dialogRef.close({ success: true });
      })
      .catch(() => (this.isLoading = false));
  }

  addActivity() {
    const activity = new FormGroup({
      activity_type: new FormControl('', Validators.required),
      contractor_org: new FormControl(0, Validators.required),
      scheduled_date: new FormControl<Date | null>(null, Validators.required),
      workman: new FormControl(0, Validators.required),
    });
    this.activities.push(activity);
  }

  removeActivity() {
    this.activities.removeAt(-1);
  }
}
