import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { compareAsc, compareDesc, startOfDay } from 'date-fns';
import { ActivityReportDialogComponent } from 'src/app/components/activity-report-dialog/activity-report-dialog.component';
import { CreateActivityDialogComponent } from 'src/app/components/create-activity-dialog/create-activity-dialog.component';
import { CreateProjectDialogComponent } from 'src/app/components/create-project-dialog/create-project-dialog.component';
import { EditActivityDialogComponent } from 'src/app/components/edit-activity-dialog/edit-activity-dialog.component';
import { AMC_ACTIVITY_STATUS } from 'src/app/core/constants';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { Activity, DialogMode, Project } from 'src/types';

@Component({
  selector: 'app-contractor-page-details',
  templateUrl: './contractor-page-details.component.html',
  styleUrls: ['./contractor-page-details.component.scss'],
})
export class ContractorPageDetailsComponent implements OnInit {
  amcId: string;
  project: Project;
  activities: Activity[];

  reviewActivities: Activity[] = [];
  upcomingActivities: Activity[] = [];
  overdueActivities: Activity[] = [];
  pastActivities: Activity[] = [];

  dateToday = startOfDay(new Date());
  amcActivityStatus = AMC_ACTIVITY_STATUS;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private amcService: AmcProjectService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(() => {
      this.amcId = this.route.snapshot.params['id'];
      Promise.all([
        this.amcService.fetchProjectById(this.amcId),
        this.amcService.fetchActivityById(this.amcId),
      ]).then(resp => {
        this.project = resp[0];
        this.activities = resp[1];
        this.setActivities();
      });
    });
  }

  setActivities() {
    this.reviewActivities = this.getReviewActivities();
    this.upcomingActivities = this.getUpcomingActivities();
    this.overdueActivities = this.getOverdueActivities();
    this.pastActivities = this.getPastActivities();
  }

  getReviewActivities() {
    return this.activities
      .filter(item => item.completed_date && item.status === 'In Review')
      .sort((a, b) => compareAsc(new Date(a.completed_date), new Date(b.completed_date)));
  }

  getPastActivities() {
    return this.activities
      .filter(item => item.completed_date && item.status !== 'In Review')
      .sort((a, b) => compareAsc(new Date(a.completed_date), new Date(b.completed_date)));
  }

  getUnapprovedActivities() {
    return this.activities
      .filter(item => item.status === 'Scheduled')
      .sort((a, b) => compareAsc(new Date(a.scheduled_date), new Date(b.scheduled_date)));
  }

  getUpcomingActivities() {
    return this.getUnapprovedActivities().filter(item => this.isUpcomingDate(item.scheduled_date));
  }

  isUpcomingDate(dateStr: string) {
    return compareDesc(this.dateToday, new Date(dateStr)) >= 0;
  }

  getOverdueActivities() {
    return this.getUnapprovedActivities().filter(item => !this.isUpcomingDate(item.scheduled_date));
  }

  goBack() {
    this.router.navigate(['/contractor']);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateActivityDialogComponent, {
      width: '900px',
      data: {
        amcId: this.amcId,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.amcService.fetchActivityById(this.amcId).then(resp => {
          this.activities = resp;
          this.setActivities();
        });
      }
    });
  }

  openActivityReportDialog(activity: Activity, mode: DialogMode): void {
    const dialogRef = this.dialog.open(ActivityReportDialogComponent, {
      width: '900px',
      data: {
        activity,
        mode,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.amcService.fetchActivityById(this.amcId).then(resp => {
          this.activities = resp;
          this.setActivities();
        });
      }
    });
  }

  editProjectDialog(): void {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, {
      width: '900px',
      data: {
        projectId: this.amcId,
        mode: 'edit',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.amcService.fetchProjectById(this.amcId).then(resp => {
          this.project = resp;
        });
      }
    });
  }

  viewProjectDialog(): void {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, {
      width: '900px',
      data: {
        projectId: this.amcId,
        mode: 'view',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
      }
    });
  }

  editActivityDialog(amcId: string, activityId: string) {
    const dialogRef = this.dialog.open(EditActivityDialogComponent, {
      width: '650px',
      data: {
        amcId,
        activityId,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.amcService.fetchActivityById(this.amcId).then(resp => {
          this.activities = resp;
          this.setActivities();
        });
      }
    });
  }
}
