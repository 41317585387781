import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';

@Component({
  selector: 'view-page-dialog',
  templateUrl: './view-project-dialog.component.html',
  styleUrls: ['./view-project-dialog.component.scss'],
})
export class ViewProjectDialog implements OnInit {
  project: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { projectId?: string },
    private amcProjectService: AmcProjectService
  ) {}
  ngOnInit(): void {
    const found = this.amcProjectService.getProjects().find(i => i.id === this.data.projectId);
    if (found) {
      console.log(found.amc_fee);
      this.project = {
        project_owner: found.project_owner,
        project_name: found.project_name,
        owner_contact_details: {
          phone_number: this.formatPhoneNumber(
            found.project_details.owner_contact_details.phone_number
          ),
          email: found.project_details.owner_contact_details.email,
        },
        site_name: found.site_name,
        site_contact_details: {
          email: found.project_details.site_contact_details.email,
          phone_number: this.formatPhoneNumber(
            found.project_details.site_contact_details.phone_number
          ),
        },
        size: found.project_details.size.toString(),
        location: {
          pincode: found.project_details.location.pincode,
          city: found.project_details.location.city,
          state: found.project_details.location.state,
          full_address: found.project_details.location.full_address,
        },
        discom: found.project_details.discom,
        grid_connection_type: found.project_details.grid_connection_type,
        phase_type: found.project_details.phase_type,
        connection_type: found.project_details.connection_type,
        blocks: found.project_details.blocks,
        panels: found.project_details.panels,
        commissioning_date: this.formatDate(found.project_details.commissioning_date),
        amc_tenure: this.formatDateRange(found.start_date, found.end_date),
        amc_contract_type: found.amc_contract_type,
        amc_fee: this.formatAmount(found.amc_fee),
        contractor_org_name: found.contractor_org ? found.contractor_org.name : '',
        cost_of_service: this.formatAmount(found.cost_of_service),
      };
    }
  }

  formatPhoneNumber(phoneNumber: string) {
    return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3);
  }

  formatDate(date: string) {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  formatDateRange(startDate: string, endDate: string) {
    if (!startDate || !endDate) return '';
    return this.formatDate(startDate) + ' - ' + this.formatDate(endDate);
  }

  formatAmount(amount: number) {
    if (amount == null) return '';
    return '₹' + amount + ' + GST';
  }

  isBlocksSet(blocks: any) {
    return blocks.length !== 1 || blocks[0].block_name !== '' || blocks[0].mounting_type !== '';
  }

  isPanelsSet(panels: any) {
    return (
      panels.length !== 1 ||
      panels[0].make !== '' ||
      panels[0].wp_per_panel !== 0 ||
      panels[0].no_of_panels !== 0
    );
  }
}
