<div *ngIf="project" class="container mx-auto px-3 pb-10 sm:px-0">
  <div class="mt-10">
    <button
      (click)="goBack()"
      type="button"
      class="inline-flex items-center rounded-md bg-secondary px-2 py-1 text-center text-sm font-normal text-white hover:bg-secondary/90 focus:outline-none focus:ring-4 focus:ring-secondary/50 sm:px-4 sm:py-2 sm:text-base sm:font-medium"
    >
      <mat-icon class="mr-1">arrow_back</mat-icon>
      BACK
    </button>
  </div>
  <div class="table-card mt-5 flex flex-col p-2 sm:mt-10 sm:p-4">
    <div class="mb-2 mt-3 flex items-center justify-between px-4">
      <div class="flex items-center gap-4">
        <h3 class="text-lg font-medium sm:text-2xl">
          {{ project.project_name }}
          {{
            project.project_details.location.city
              ? '| ' + project.project_details.location.city
              : ''
          }}
        </h3>

        <span
          [ngClass]="{
            'text-[0.875rem] font-semibold uppercase': true,
            'text-leaf': project.amc_status == 'active',
            'text-red-600': project.amc_status == 'expired',
            'text-slate-400': project.amc_status == 'draft',
          }"
        >
          {{ project.amc_status }}
        </span>
      </div>

      <!-- <button
        type="button"
        class="inline-flex items-center rounded-full bg-leaf px-4 py-2 text-center text-base font-medium text-white hover:bg-leaf/90 focus:outline-none focus:ring-4 focus:ring-leaf/50"
      >
        Extend AMC
      </button> -->
    </div>

    <div class="grid grid-cols-1 gap-4 px-2 pb-4 pt-2 sm:grid-cols-2 sm:pt-8">
      <div class="table-card p-3 sm:p-10">
        <div class="mb-10 flex w-full items-center justify-between">
          <h4 class="font-semibold">Project Details</h4>

          <button
            (click)="editProjectDialog()"
            type="button"
            class="inline-flex items-center rounded-md border-2 border-[#A9A9A9] bg-white px-3 py-1 text-center text-sm font-medium text-[#A9A9A9] hover:bg-gray-50 focus:outline-none focus:ring-4 focus:ring-gray-100"
          >
            <mat-icon class="mat-icons-outlined mr-2 text-base"> edit </mat-icon>
            Edit Details
          </button>
        </div>

        <div class="project-info-item">
          <h5>Project Name</h5>
          <span>:</span>
          <span>{{ project.project_name }}</span>
        </div>
        <div class="project-info-item">
          <h5>Contractor</h5>
          <span>:</span>

          <span *ngIf="project.contractor_org && project.contractor_org.name; else noContractor">
            {{ project.contractor_org.name }}
          </span>
          <ng-template #noContractor>
            <span class="not-available">Not Available</span>
          </ng-template>
        </div>
        <div class="project-info-item">
          <h5>Project Size</h5>
          <span>:</span>
          <span>{{ project.project_details.size }} kWp</span>
        </div>
        <div class="project-info-item">
          <h5>Location</h5>
          <span>:</span>
          <span *ngIf="project.project_details.location.city; else noLocation"
            >{{ project.project_details.location.city }},
            {{ project.project_details.location.state }}</span
          >
          <ng-template #noLocation>
            <span class="not-available">Not Available</span>
          </ng-template>
        </div>
        <div class="project-info-item">
          <h5>AMC Tenure</h5>
          <span>:</span>
          <span *ngIf="project.start_date && project.end_date; else noTenure"
            >{{ project.start_date | dateFormatter }} - {{ project.end_date | dateFormatter }}</span
          >
          <ng-template #noTenure>
            <span class="not-available">Not Available</span>
          </ng-template>
        </div>
        <div (click)="viewProjectDialog()" class="more">
          <span>More Details</span>
        </div>
      </div>
      <div class="table-card p-3 sm:p-10">
        <h4 class="mb-3 font-semibold sm:mb-10">Review Activities</h4>
        <div class="scroll-container max-h-[450px] w-full">
          <table class="w-full table-auto">
            <thead class="sticky top-0 border-b border-black bg-white">
              <tr>
                <th>ID</th>
                <th>Activity Type</th>
                <th>Submission Date</th>
                <th>Submitted By</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="">
              <tr *ngIf="!reviewActivities.length">
                <td class="not-available" colspan="4">No new activity reports to review</td>
              </tr>
              <tr
                *ngFor="let activity of reviewActivities"
                (click)="openActivityReportDialog(activity, 'edit')"
                class="cursor-pointer transition hover:bg-slate-200"
              >
                <td>{{ activity.id.slice(0, 7) }}</td>
                <td>{{ activity.activity_type }}</td>
                <td>{{ activity.completed_date | dateFormatter }}</td>
                <td>{{ activity.workman?.name }}</td>
                <td class="!text-end">
                  <span [matTooltip]="activity.status" matTooltipPosition="above">
                    <mat-icon class="mat-icons-outlined mr-2 cursor-pointer text-xl text-gray-400">
                      info
                    </mat-icon>
                  </span>

                  <!-- <div class="report">
                    <span>View Report</span>
                    <mat-icon class="material-icons-outlined">visibility</mat-icon>
                  </div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="table-card p-3 sm:p-10">
        <div class="mb-3 flex w-full items-center justify-between sm:mb-10">
          <h4 class="font-semibold">Upcoming Activities</h4>

          <button
            (click)="openDialog()"
            type="button"
            class="inline-flex items-center rounded-md border-2 border-secondary bg-secondary px-3 py-1 text-center text-sm font-medium text-white hover:bg-secondary/90 focus:outline-none focus:ring-4 focus:ring-secondary/50 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-400 disabled:text-slate-200"
            [disabled]="project.amc_status === 'draft'"
          >
            <!-- matTooltip="Activities cannot be scheduled for draft Projects"
            [matTooltipPosition]="'above'" -->
            <mat-icon class="material-icons-outlined mr-2 text-base"> schedule </mat-icon>

            Schedule
          </button>
        </div>
        <div class="scroll-container max-h-[270px] w-full">
          <table class="w-full table-auto">
            <thead class="sticky top-0 border-b border-black bg-white">
              <tr>
                <th>Schedule Date</th>
                <th>AMC Type</th>
                <th class="w-52">Contractor</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr *ngIf="!upcomingActivities.length">
                <td class="not-available" colspan="4">No upcoming activities</td>
              </tr>
              <tr *ngFor="let activity of upcomingActivities">
                <td>{{ activity.scheduled_date | dateFormatter }}</td>
                <td>{{ activity.activity_type }}</td>
                <td class="max-w-52 overflow-hidden text-ellipsis text-nowrap">
                  {{ activity.contractor_org | contractorName }}
                </td>
                <td>
                  <mat-icon
                    (click)="editActivityDialog(amcId, activity.id)"
                    class="mat-icons-outlined mr-2 cursor-pointer text-xl"
                  >
                    edit
                  </mat-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h4 class="mb-3 mt-9 font-semibold sm:mb-10 sm:mt-16">Overdue Activities</h4>
        <div class="scroll-container max-h-[270px] w-full">
          <table class="w-full table-auto">
            <thead class="sticky top-0 border-b border-black bg-white">
              <tr>
                <th>Schedule Date</th>
                <th>AMC Type</th>
                <th class="w-52">Contractor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!overdueActivities.length">
                <td class="not-available" colspan="3">No overdue activities</td>
              </tr>
              <tr *ngFor="let activity of overdueActivities">
                <td class="error">{{ activity.scheduled_date | dateFormatter }}</td>
                <td class="error">{{ activity.activity_type }}</td>
                <td class="error max-w-52 overflow-hidden text-ellipsis text-nowrap">
                  <!-- <div class="flex items-center justify-between">
                    <span>{{ activity.contractor_org | contractorName }}</span>
                    <mat-icon
                      (click)="editActivityDialog(amcId, activity.id)"
                      class="mat-icons-outlined mr-2 cursor-pointer text-xl"
                    >
                      edit
                    </mat-icon>
                  </div> -->
                  {{ activity.contractor_org | contractorName }}
                </td>
                <td>
                  <mat-icon
                    (click)="editActivityDialog(amcId, activity.id)"
                    class="mat-icons-outlined mr-2 cursor-pointer text-xl"
                  >
                    edit
                  </mat-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="table-card p-3 sm:p-10">
        <h4 class="mb-3 font-semibold sm:mb-10">Past Activities</h4>
        <div class="scroll-container max-h-[550px] w-full">
          <table class="w-full table-auto">
            <thead class="sticky top-0 border-b border-black bg-white">
              <tr>
                <th>ID</th>
                <th>Activity Type</th>
                <th class="!text-center">Scheduled Date</th>
                <th class="!text-center">Completion Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="">
              <tr *ngIf="!pastActivities.length">
                <td class="not-available" colspan="4">No past activities</td>
              </tr>
              <tr
                *ngFor="let activity of pastActivities; let i = index"
                (click)="openActivityReportDialog(activity, 'view')"
                class="cursor-pointer transition hover:bg-slate-200"
              >
                <td>{{ activity.id.slice(0, 7) }}</td>
                <td>{{ activity.activity_type }}</td>
                <td class="!text-center">{{ activity.scheduled_date | dateFormatter }}</td>
                <td class="!text-center">
                  {{
                    (activity.approval_date ? activity.approval_date : activity.completed_date)
                      | dateFormatter
                  }}
                </td>
                <td class="!text-end">
                  <div [matTooltip]="activity.status" matTooltipPosition="above">
                    <span *ngIf="activity.status === amcActivityStatus.APPROVED">
                      <mat-icon class="mat-icons-outlined mr-2 text-xl text-lime-500">
                        check_circle
                      </mat-icon>
                    </span>
                    <span *ngIf="activity.status === amcActivityStatus.REJECTED">
                      <mat-icon class="mat-icons-outlined mr-2 text-xl text-red-500">
                        cancel
                      </mat-icon>
                    </span>
                    <span *ngIf="activity.status === amcActivityStatus.CANCELLED">
                      <mat-icon class="mat-icons-outlined mr-2 text-xl text-gray-400">
                        cancel
                      </mat-icon>
                    </span>
                    <span *ngIf="activity.status === amcActivityStatus.COMPLETED">
                      <mat-icon class="mat-icons-outlined mr-2 text-xl text-lime-500">
                        done_all
                      </mat-icon>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
