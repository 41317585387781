import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOtpInputModule } from 'ng-otp-input';
import { provideNgxWebstorage, withLocalStorage } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActivityReportDialogComponent } from './components/activity-report-dialog/activity-report-dialog.component';
import { AddOrgUserDialogComponent } from './components/add-org-user-dialog/add-org-user-dialog.component';
import { BusinessDashboardComponent } from './components/business-dashboard/business-dashboard.component';
import { ContractorDashboardComponent } from './components/contractor-dashboard/contractor-dashboard.component';
import { CreateActivityDialogComponent } from './components/create-activity-dialog/create-activity-dialog.component';
import { CreateProjectDialogComponent } from './components/create-project-dialog/create-project-dialog.component';
import { EditActivityDialogComponent } from './components/edit-activity-dialog/edit-activity-dialog.component';
import { EditOrgUserDialogComponent } from './components/edit-org-user-dialog/edit-org-user-dialog.component';
import { FabIconComponent } from './components/fab-icon/fab-icon.component';
import { FillActivityReportDialogComponent } from './components/fill-activity-report-dialog/fill-activity-report-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MediaPreviewDialogComponent } from './components/media-preview-dialog/media-preview-dialog.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ViewProjectDialog } from './components/view-project-dialog/view-project-dialog.component';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { AuthService } from './core/auth/auth.service';
import { ContractorNamePipe } from './core/pipes/contractor-name.pipe';
import { DateFormatterPipe } from './core/pipes/date-formatter.pipe';
import { AppService } from './core/services/app.service';
import { UserProfileService } from './core/services/user-profile.service';
import { DndDirective } from './dnd.directive';
import { BusinessPageDetailsComponent } from './pages/business-page-details/business-page-details.component';
import { BusinessPageComponent } from './pages/business-page/business-page.component';
import { ContractorPageDetailsComponent } from './pages/contractor-page-details/contractor-page-details.component';
import { ContractorPageComponent } from './pages/contractor-page/contractor-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MyOrganizationPageComponent } from './pages/my-organization-page/my-organization-page.component';
import { OrgSignupPageComponent } from './pages/org-signup-page/org-signup-page.component';
import { OtpVerifyComponent } from './pages/otp-verify/otp-verify.component';
import { RegistrationCompleteComponent } from './pages/registration-complete/registration-complete.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';

function initializeAppFactory(appService: AppService) {
  return () => appService.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    BusinessDashboardComponent,
    ContractorDashboardComponent,
    ContractorPageComponent,
    BusinessPageComponent,
    NavbarComponent,
    SignupPageComponent,
    LoginPageComponent,
    OrgSignupPageComponent,
    FabIconComponent,
    CreateProjectDialogComponent,
    ViewProjectDialog,
    BusinessPageDetailsComponent,
    DateFormatterPipe,
    ContractorNamePipe,
    EditActivityDialogComponent,
    CreateActivityDialogComponent,
    MyOrganizationPageComponent,
    AddOrgUserDialogComponent,
    EditOrgUserDialogComponent,
    LoadingComponent,
    HomePageComponent,
    ContractorPageDetailsComponent,
    ActivityReportDialogComponent,
    OtpVerifyComponent,
    RegistrationCompleteComponent,
    MediaPreviewDialogComponent,
    FillActivityReportDialogComponent,
    DndDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    NgIf,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatChipsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    JsonPipe,
    MatStepperModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule,
    NgOtpInputModule,
  ],
  providers: [
    AppService,
    AuthService,
    UserProfileService,
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(withLocalStorage()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService, UserProfileService, HttpClient],
      multi: true,
    },
    // for setting the date format, for more see:
    // https://material.angular.io/components/datepicker/overview#internationalization
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    provideMomentDateAdapter(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
