<mat-dialog-content class="!px-2 !py-4 sm:!p-8">
  <h1 class="m-2 text-base font-medium text-primary sm:m-5 sm:text-xl">More Project Details</h1>
  <mat-stepper class="no-header" linear #stepper>
    <mat-step>
      <h2 class="form-group-title">Project Details</h2>
      <div class="inline-field">
        <span>Project Owner</span>
        <span>{{ project.project_owner }}</span>
      </div>
      <h3 class="form-group-title-2">Owner's Contact Details</h3>
      <div class="inline-field">
        <span>Phone Number</span>
        <span>{{ project.owner_contact_details.phone_number }}</span>
      </div>
      <div class="inline-field">
        <span>Email ID</span>
        <span>{{ project.owner_contact_details.email }}</span>
      </div>
      <div class="inline-field">
        <span>Project Name</span>
        <span>{{ project.project_name }}</span>
      </div>
      <div class="inline-field">
        <span>Site Name</span>
        <span>{{ project.site_name }}</span>
      </div>

      <h3 class="form-group-title-2">Site's Contact Details</h3>
      <div class="inline-field pl-3 sm:pl-10">
        <span>Phone Number</span>
        <span>{{ project.site_contact_details.phone_number }}</span>
      </div>
      <div class="inline-field pl-3 sm:pl-10">
        <span>Email ID</span>
        <span>{{ project.site_contact_details.email }}</span>
      </div>
      <div class="inline-field">
        <span>Project Size (in kWp)</span>
        <span>{{ project.size }}</span>
      </div>

      <h2 class="form-group-title">Address Information</h2>
      <div class="inline-field">
        <span>Pincode</span>
        <span [ngClass]="{ 'not-set': project.location.pincode === '' }">{{
          project.location.pincode !== '' ? project.location.pincode : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>City</span>
        <span [ngClass]="{ 'not-set': project.location.city === '' }">{{
          project.location.city !== '' ? project.location.city : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>State</span>
        <span [ngClass]="{ 'not-set': project.location.state === '' }">{{
          project.location.state != '' ? project.location.state : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>Full Address</span>
        <span [ngClass]="{ 'not-set': project.location.full_address === '' }">{{
          project.location.full_address != '' ? project.location.full_address : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>DISCOM</span>
        <span [ngClass]="{ 'not-set': project.discom === '' }">{{
          project.discom !== '' ? project.discom : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>Grid Connection Type</span>
        <span [ngClass]="{ 'not-set': project.grid_connection_type === '' }">{{
          project.grid_connection_type !== '' ? project.grid_connection_type : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>Phase Type</span>
        <span [ngClass]="{ 'not-set': project.phase_type === '' }">{{
          project.phase_type !== '' ? project.phase_type : 'Not Set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>Connection Type</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.connection_type !== '' ? project.connection_type : 'Not Set'
        }}</span>
      </div>

      <h2 class="form-group-title">Block</h2>
      <div *ngIf="!isBlocksSet(project.blocks); else display_blocks" class="text-center">
        <span class="not-set">Blocks are not set</span>
      </div>
      <ng-template #display_blocks>
        <div class="mb-2" *ngFor="let block of project.blocks; let i = index">
          <h3 class="mb-1 mt-3 font-medium text-slate-600">Block {{ i + 1 }}</h3>
          <div class="inline-field">
            <span>Name</span>
            <span>{{ block.block_name }}</span>
          </div>
          <div class="inline-field">
            <span>Mounting Type</span>
            <span>{{ block.mounting_type }}</span>
          </div>
        </div>
      </ng-template>

      <h2 class="form-group-title">Panels Details</h2>
      <div class="mb-10">
        <div class="text-center" *ngIf="!isPanelsSet(project.panels); else display_panels">
          <span class="not-set">Panels are not set</span>
        </div>
        <ng-template #display_panels>
          <div class="mb-3 flex justify-evenly" *ngFor="let panel of project.panels">
            <div class="flex flex-col items-center">
              <span>Panel Make</span>
              <span>{{ panel.make }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span>Wp / Panel</span>
              <span>{{ panel.wp_per_panel }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span>No. of Panels</span>
              <span>{{ panel.no_of_panels }}</span>
            </div>
            <div class="flex flex-col items-center justify-center">
              <span>=</span>
            </div>
            <div class="flex flex-col items-center">
              <span>Total Wp</span>
              <span>{{ panel.wp_per_panel * panel.no_of_panels }}</span>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="inline-field">
        <span>Commissioning Date</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.commissioning_date !== '' ? project.commissioning_date : 'Not Set'
        }}</span>
      </div>

      <div class="stepper-nav-container">
        <button matStepperNext type="button" class="next-btn">
          Next
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <h2 class="form-group-title">AMC Contact Details</h2>
      <div class="inline-field">
        <span>AMC Tenure</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.amc_tenure !== '' ? project.amc_tenure : 'Not set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>AMC Type</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.amc_contract_type !== null ? project.amc_contract_type : 'Not set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>AMC Fee From Customer</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.amc_fee !== '' ? project.amc_fee : 'Not set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>Contractor</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.contractor_org_name !== '' ? project.contractor_org_name : 'Not set'
        }}</span>
      </div>
      <div class="inline-field">
        <span>Cost of Service</span>
        <span [ngClass]="{ 'not-set': project.connection_type === '' }">{{
          project.cost_of_service !== '' ? project.cost_of_service : 'Not set'
        }}</span>
      </div>
      <div class="stepper-nav-container">
        <button matStepperPrevious type="button" class="back-btn">
          <mat-icon>navigate_before</mat-icon>
          Previous
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
