<mat-dialog-content>
  <div class="mx-auto flex max-w-3xl flex-col">
    <div class="mb-8 flex flex-col sm:mb-5">
      <div class="flex flex-col justify-between gap-y-3 sm:flex-row">
        <h1 class="text-xl font-medium text-secondary">Activity Report</h1>
        <div class="flex flex-col items-end gap-[2px] text-xs sm:items-start sm:text-sm">
          <div class="flex items-center">
            <span
              class="font-semibold text-slate-400"
              [matTooltip]="'Activity ID'"
              [matTooltipPosition]="'above'"
            >
              {{ data.activity.id.slice(0, 7) }}
            </span>
            <div class="point-divider"></div>
            <span
              [ngClass]="{
                'font-semibold uppercase': true,
                'text-leaf':
                  data.activity.status == 'Approved' || data.activity.status == 'Completed',
                'text-red-600': data.activity.status == 'Rejected',
                'text-slate-400': data.activity.status == 'Cancelled',
              }"
            >
              {{ data.activity.status }}
            </span>
          </div>
          <div class="flex items-center text-slate-400">
            <h5>Activity Type</h5>
            <div class="point-divider"></div>
            <span>{{ data.activity.activity_type }}</span>
          </div>
          <div class="flex items-center text-slate-400">
            <h5>Scheduled Date</h5>
            <div class="point-divider"></div>
            <span>{{ data.activity.scheduled_date | dateFormatter }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="project-info-item">
      <h5>Submitted by</h5>
      <span>:</span>
      <span>{{ data.activity.workman?.name || 'Not available' }}</span>
    </div>

    <div class="project-info-item">
      <h5>Date of Submission</h5>
      <span>:</span>
      <span>{{ data.activity.completed_date | dateFormatter }}</span>
    </div>

    <div class="project-info-item">
      <h5>Site Photos & Videos</h5>
      <span>:</span>
      <div *ngIf="filteredFiles.length === 0">
        <span class="italic"> No site photos or videos available </span>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-5 sm:grid-cols-4">
      <div *ngFor="let file of filteredFiles; index as i">
        <div class="relative">
          <mat-spinner
            *ngIf="file.loading"
            class="!absolute bottom-0 left-0 right-0 top-0 m-auto !w-8"
          ></mat-spinner>
          <img
            (click)="openMediaPreview(i)"
            *ngIf="isImageUrl(file.url)"
            class="h-40 w-full cursor-pointer rounded-lg object-cover"
            [src]="file.url"
            (load)="onFileLoad(i)"
            alt=""
          />

          <video
            (click)="openMediaPreview(i)"
            type="video/mp4"
            (loadedmetadata)="onFileLoad(i)"
            playsinline
            loop
            autoplay
            [muted]="true"
            *ngIf="isVideoUrl(file.url)"
            class="h-40 w-full cursor-pointer rounded-lg object-cover"
          >
            <source [src]="file.url" />
          </video>
        </div>
      </div>
    </div>
    <div [ngClass]="{ more: files.length }">
      <span *ngIf="files.length > 4" (click)="toggleShowMoreFiles()">{{
        showMoreFiles ? 'Less' : 'More'
      }}</span>
    </div>

    <div class="project-info-item">
      <h5>Site Observations</h5>
      <span>:</span>
      <div *ngIf="!data.activity.extra_details?.activityChecklist">
        <span class="italic"> No site observations available </span>
      </div>
    </div>

    <mat-accordion
      *ngIf="data.activity.extra_details?.activityChecklist"
      class="checklist-headers-align"
      multi
    >
      <mat-expansion-panel *ngFor="let checklist of amcActivityChecklist">
        <mat-expansion-panel-header>
          <mat-panel-title class="!text-sm sm:!text-base"> {{ checklist.name }} </mat-panel-title>
        </mat-expansion-panel-header>

        <ul class="space-y-4 px-4">
          <li
            class="flex w-full items-center justify-between"
            *ngFor="
              let item of data.activity.extra_details!.activityChecklist![checklist.key].checklist
            "
          >
            <span class="w-8/12 text-sm text-[#6A6767] sm:text-base"> {{ item.desc }}</span>

            <div
              *ngIf="item.status"
              class="flex h-[1.3rem] w-[1.3rem] items-center justify-center rounded-full bg-primary sm:h-[2.1rem] sm:w-[2.1rem]"
            >
              <mat-icon
                class="!h-[15px] !w-[15px] text-[15px] text-white sm:!h-[24px] sm:!w-[24px] sm:text-[24px]"
                >done</mat-icon
              >
            </div>

            <div
              *ngIf="!item.status"
              class="ml-auto flex h-[1.3rem] w-[1.3rem] items-center justify-center rounded-full bg-space-gray sm:h-[2.1rem] sm:w-[2.1rem]"
            >
              <mat-icon
                class="!h-[15px] !w-[15px] text-[15px] text-white sm:!h-[24px] sm:!w-[24px] sm:text-[24px]"
                >close</mat-icon
              >
            </div>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <form [formGroup]="editActivityReportForm">
      <div class="project-info-item mt-7">
        <h5>{{ isBusiness ? "Contractor's Remarks" : 'Your Remarks' }}</h5>
        <span>:</span>
        <div *ngIf="(isBusiness && data.mode !== 'view') || data.mode === 'view'">
          <span class="italic">
            {{
              data.activity.notes?.contractor_remarks
                ? data.activity.notes?.contractor_remarks
                : 'No contractor remarks available'
            }}</span
          >
        </div>
      </div>
      <mat-form-field
        *ngIf="!isBusiness && data.mode !== 'view'"
        class="w-full"
        appearance="outline"
      >
        <textarea matInput formControlName="contractor_remarks"> </textarea>
      </mat-form-field>

      <div *ngIf="isBusiness || data.mode === 'view'">
        <div class="project-info-item">
          <h5>{{ isBusiness ? 'Your Remarks' : "Business's Remarks" }}</h5>
          <span>:</span>
          <div *ngIf="data.mode === 'view'">
            <span class="italic">
              {{
                data.activity.notes?.business_remarks
                  ? data.activity.notes?.business_remarks
                  : 'No business remarks available'
              }}
            </span>
          </div>
        </div>
        <mat-form-field *ngIf="data.mode !== 'view'" class="w-full" appearance="outline">
          <textarea matInput formControlName="business_remarks"> </textarea>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="data.mode !== 'view'" class="stepper-nav-container mb-2">
      <button
        *ngIf="!isBusiness"
        [disabled]="isLoading"
        (click)="onSubmit()"
        type="button"
        class="save-btn"
      >
        Save & Close
      </button>
      <button
        *ngIf="isBusiness"
        [disabled]="isLoading"
        (click)="onSubmit('Approved')"
        type="button"
        class="save-btn"
      >
        Approve
        <mat-icon class="ml-1">done</mat-icon>
      </button>

      <button
        *ngIf="isBusiness"
        [disabled]="isLoading"
        (click)="onSubmit('Rejected')"
        type="button"
        class="del-btn"
      >
        Reject
        <mat-icon class="ml-1">close</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
