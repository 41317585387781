<div class="h-screen w-screen">
  <nav
    class="fixed start-0 top-0 z-20 w-full border-b border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
  >
    <div class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
      <img src="/assets/GreenCare by Oorjan.png" class="h-11 lg:h-12" alt="GreenCare Logo" />

      <div class="mr-[0.22rem] flex space-x-3 md:order-2 md:space-x-0 rtl:space-x-reverse">
        <div class="flex flex-col items-center space-x-2 text-sm sm:flex-row lg:text-base">
          <span>New to GreenCare?</span>

          <span
            (click)="goToSignUp()"
            class="cursor-pointer font-medium text-secondary hover:underline"
            >Join Now</span
          >
        </div>
      </div>
    </div>
  </nav>
  <div
    class="flex h-full flex-col items-center justify-start space-y-16 px-4 pt-32 lg:flex-row lg:justify-center lg:space-y-0 lg:pt-0"
  >
    <div class="flex w-full justify-center lg:border-r-2">
      <img
        src="/assets/Greencare services.png"
        class="max-w-xs pr-2 lg:mr-12 lg:max-w-lg lg:pr-10"
        alt="Engineers with solar panel"
      />
    </div>
    <div class="flex w-full justify-center">
      <div class="w-full max-w-sm xl:max-w-md">
        <h3 class="mb-5 w-full text-center text-2xl font-bold text-gray-700">Sign in with OTP</h3>
        <form [formGroup]="signinForm">
          <div class="form-container">
            <div class="w-full">
              <label class="mb-1" for="signin-phone_number"
                ><span class="font-normal text-gray-500">Phone</span><sup>*</sup></label
              >
              <mat-form-field class="w-full rounded-md" appearance="outline">
                <span matPrefix class="pl-3">+91</span>
                <input
                  maxlength="10"
                  formControlName="phone_number"
                  matInput
                  type="text"
                  id="signin-phone_number"
                />
                <mat-error
                  *ngIf="phone_number?.hasError('pattern') && !phone_number?.hasError('required')"
                >
                  Please enter a valid phone number
                </mat-error>
                <mat-error *ngIf="phone_number?.hasError('required')">
                  Phone is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <button
          [disabled]="!signinForm.valid"
          type="submit"
          class="m-auto !flex w-1/2"
          mat-flat-button
          color="accent"
          (click)="onSubmit()"
        >
          <span class="text-white">Get OTP</span>
        </button>
      </div>
    </div>
  </div>
</div>
