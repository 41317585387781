<div mat-dialog-content>
  <h1 class="m-5 text-xl font-medium text-primary">Edit Member</h1>

  <form [formGroup]="editOrgForm">
    <div class="inline-input">
      <p class="w-1/2">Name</p>
      <div class="w-1/2">
        <span class="italic">
          {{ this.name }}
        </span>
      </div>
    </div>

    <div class="inline-input">
      <p class="w-1/2">Phone Number</p>
      <div class="w-1/2">
        <span class="italic">
          {{ this.phoneNumber }}
        </span>
      </div>
    </div>

    <div class="inline-input">
      <p class="w-1/2">Email</p>
      <div class="w-1/2">
        <span class="italic">
          {{ this.email }}
        </span>
      </div>
    </div>

    <div class="inline-input !flex-nowrap">
      <label class="flex w-1/2">Role</label>
      <mat-form-field class="flex w-1/2" appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Amc Role" formControlName="role_type">
          <mat-option *ngFor="let amcRole of AmcRoles" [value]="amcRole">{{ amcRole }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="stepper-nav-container">
    <button
      (click)="onSubmit()"
      [disabled]="!editOrgForm.valid || isLoading"
      type="button"
      class="save-btn"
    >
      Save
    </button>
    <!-- <button type="button" class="del-btn">Remove</button> -->
  </div>
</div>
