<!-- <div class="fixed flex w-full">
  <div class="ml-8 mr-8 mt-8 text-left text-base">
    <img src="/assets/GreenCare by Oorjan.png" class="w-2/5" alt="GreenCare Logo" />
  </div>
  <div class="ml-auto mr-8 mt-8 text-right text-base">
    Already have an account?
    <span
      (click)="goToLogIn()"
      class="ml-1 cursor-pointer font-medium text-secondary hover:underline"
      >Sign in</span
    >
  </div>
</div>

<div class="flex h-screen w-full items-center">
  <div class="flex basis-6/12 justify-center">
    <img src="/assets/Greencare services.png" class="w-3/4 pl-8" alt="Engineers with solar panel" />
  </div>
  <div class="flex h-full basis-6/12 flex-col items-center">
    <div class="mb-8 flex h-full w-full items-center justify-center px-4 pt-12">
      <mat-card class="w-3/5 border border-solid">
        <mat-card-content>
          <div class="w-full max-w-md p-6">
            <h3 class="mb-8 w-full text-center font-sans text-xl font-bold text-gray-700">
              Create Your Account
            </h3>
            <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
              <div class="form-container">
                <label class="mb-1" for="signup-name"
                  ><span class="font-normal">Name</span><sup>*</sup></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <input id="signup-name" matInput type="text" formControlName="name" />
                  <mat-error *ngIf="name?.hasError('required')">
                    Name is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <label class="mb-1" for="signup-email"
                  ><span class="font-normal">Email</span><sup>*</sup></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <input id="signup-email" matInput type="email" formControlName="email" />
                  <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="email?.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                <label class="mb-1" for="signup-phone_number"
                  ><span class="font-normal">Phone</span><sup>*</sup></label
                >
                <mat-form-field class="w-full" appearance="outline">
                  <span matPrefix class="pl-3">+91</span>
                  <input
                    maxlength="10"
                    formControlName="phone_number"
                    matInput
                    type="text"
                    id="signup-phone_number"
                  />

                  <mat-error
                    *ngIf="phone_number?.hasError('pattern') && !phone_number?.hasError('required')"
                  >
                    Please enter a valid phone number
                  </mat-error>
                  <mat-error *ngIf="phone_number?.hasError('required')">
                    Phone is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <button
                [disabled]="!signupForm.valid"
                type="submit"
                mat-flat-button
                color="accent"
                class="mt-4 w-full"
              >
                <span class="text-white">Sign Up</span>
              </button>
              <p class="mt-2 text-center font-sans text-xs text-gray-500">
                You will receive a verification code on your mobile number
              </p>
            </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

-->

<div class="h-screen w-screen">
  <nav
    class="fixed start-0 top-0 z-20 w-full border-b border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
  >
    <div class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
      <img src="/assets/GreenCare by Oorjan.png" class="h-11 lg:h-12" alt="GreenCare Logo" />

      <div class="mr-[0.22rem] flex space-x-3 md:order-2 md:space-x-0 rtl:space-x-reverse">
        <div class="flex flex-col items-center space-x-2 text-sm sm:flex-row lg:text-base">
          <span>Already have an account?</span>

          <span
            (click)="goToLogIn()"
            class="cursor-pointer font-medium text-secondary hover:underline"
            >Log In</span
          >
        </div>
      </div>
    </div>
  </nav>
  <div
    class="flex h-full flex-col items-center justify-start space-y-16 px-4 pt-20 lg:flex-row lg:justify-center lg:space-y-0 lg:pt-0"
  >
    <div class="hidden w-full justify-center lg:flex lg:border-r-2">
      <img
        src="/assets/Greencare services.png"
        class="max-w-xs pr-2 lg:mr-12 lg:max-w-lg lg:pr-10"
        alt="Engineers with solar panel"
      />
    </div>
    <div class="flex w-full items-center justify-center lg:pt-20">
      <div class="max-w-sm xl:max-w-md">
        <mat-card class="max-w-sm border border-solid">
          <mat-card-content>
            <div class="w-full max-w-md p-6">
              <h3 class="mb-8 w-full text-center font-sans text-xl font-bold text-gray-700">
                Create Your Account
              </h3>
              <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                <div class="form-container">
                  <label class="mb-1" for="signup-name"
                    ><span class="font-normal">Name</span><sup>*</sup></label
                  >
                  <mat-form-field class="w-full" appearance="outline">
                    <input id="signup-name" matInput type="text" formControlName="name" />
                    <mat-error *ngIf="name?.hasError('required')">
                      Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <label class="mb-1" for="signup-email"
                    ><span class="font-normal">Email</span><sup>*</sup></label
                  >
                  <mat-form-field class="w-full" appearance="outline">
                    <input id="signup-email" matInput type="email" formControlName="email" />
                    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">
                      Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="email?.hasError('required')">
                      Email is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <label class="mb-1" for="signup-phone_number"
                    ><span class="font-normal">Phone</span><sup>*</sup></label
                  >
                  <mat-form-field class="w-full" appearance="outline">
                    <span matPrefix class="pl-3">+91</span>
                    <input
                      maxlength="10"
                      formControlName="phone_number"
                      matInput
                      type="text"
                      id="signup-phone_number"
                    />

                    <mat-error
                      *ngIf="
                        phone_number?.hasError('pattern') && !phone_number?.hasError('required')
                      "
                    >
                      Please enter a valid phone number
                    </mat-error>
                    <mat-error *ngIf="phone_number?.hasError('required')">
                      Phone is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <button
                  [disabled]="!signupForm.valid"
                  type="submit"
                  mat-flat-button
                  color="accent"
                  class="mt-4 w-full"
                >
                  <span class="text-white">Sign Up</span>
                </button>
                <p class="mt-2 text-center font-sans text-xs text-gray-500">
                  You will receive a verification code on your mobile number
                </p>
              </form>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
