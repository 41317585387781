<div mat-dialog-content>
  <h1 class="m-5 text-xl font-medium text-secondary">Edit Activity</h1>

  <form [formGroup]="editActivityForm">
    <div class="inline-input">
      <label for="add-project-mounting-type">AMC Type</label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select
          placeholder="Type of Activity"
          id="add-project-offering"
          formControlName="activity_type"
          class="!text-sm sm:!text-base"
        >
          <mat-option
            class="!text-sm sm:!text-base"
            *ngFor="let amcActivity of amcActivityType"
            [value]="amcActivity"
            >{{ amcActivity }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!isContractor" class="inline-input">
      <label for="add-project-mounting-type">Contractor<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select
          placeholder="Contractor"
          id="add-project-offering"
          formControlName="contractor_org"
          class="!text-sm sm:!text-base"
        >
          <mat-option
            class="!text-sm sm:!text-base"
            *ngFor="let contractor of contractors"
            [value]="contractor.id"
            >{{ contractor.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label for="add-project-mounting-type">Workman<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select class="!text-sm sm:!text-base" placeholder="Workmen" formControlName="workman">
          <mat-option
            class="!text-sm sm:!text-base"
            *ngFor="let workman of workmanList"
            [value]="workman.id"
          >
            <div class="flex items-center">
              <span>
                {{ workman.name }}
              </span>
              <div class="point-divider"></div>
              <span class="!text-xs italic text-gray-400">
                {{ workman.role_type }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="inline-input">
      <label for="add-project-mounting-type">Schedule Date<sup>*</sup></label>
      <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
        <input
          class="!text-sm sm:!text-base"
          placeholder="Date"
          matInput
          formControlName="scheduled_date"
          [matDatepicker]="editScheduledDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="editScheduledDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #editScheduledDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div [ngClass]="'stepper-nav-container ' + (isContractor ? 'justify-center' : 'justify-between')">
    <button
      (click)="editActivity()"
      [disabled]="!editActivityForm.valid || isLoading"
      type="button"
      class="save-btn"
    >
      Save & Close
    </button>
    <div *ngIf="!isContractor" class="mt-2 flex justify-center gap-5 sm:mt-0 sm:justify-end">
      <button
        (click)="markAsCompleted()"
        [disabled]="!editActivityForm.valid || isLoading"
        type="button"
        class="mark-complete-btn"
      >
        <mat-icon class="mat-icons-outlined mr-2 !overflow-visible"> check_circle </mat-icon>
        MARK AS COMPLETED
      </button>
      <button
        (click)="deleteActivityDialog()"
        [disabled]="isLoading"
        type="button"
        class="delete-btn"
      >
        <mat-icon class="mat-icons-outlined mr-2"> delete </mat-icon>
        DELETE
      </button>
    </div>
  </div>
</div>
