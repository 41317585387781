import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';

@Component({
  selector: 'delete-activity-dialog',
  templateUrl: './delete-activity-dialog.component.html',
  styleUrls: ['./delete-activity-dialog.component.scss'],
})
export class DeleteActivityDialogComponent {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteActivityDialogComponent>,
    private amcProjectService: AmcProjectService,
    @Inject(MAT_DIALOG_DATA) public data: { activityId: string }
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }

  delete(): void {
    this.isLoading = true;
    this.amcProjectService
      .deleteActivity(this.data.activityId)
      .then(res => {
        if (res.success) {
          this.isLoading = false;
          this.dialogRef.close({ success: true });
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
