import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import Fuse, { IFuseOptions } from 'fuse.js';
import { AmcProjectService } from 'src/app/core/services/amc-project.service';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { sortProjectsByEndDate } from 'src/app/core/utils';
import type { ParsedProject, Project, ProjectOwnerFilterOptions } from 'src/types';
import { CreateProjectDialogComponent } from '../create-project-dialog/create-project-dialog.component';

const fuseOptions: IFuseOptions<Project> = {
  threshold: 0.3,
  keys: ['id', 'project_name', 'project_details.location.city', 'project_details.location.state'],
};

@Component({
  selector: 'app-business-dashboard',
  templateUrl: './business-dashboard.component.html',
  styleUrls: ['./business-dashboard.component.scss'],
})
export class BusinessDashboardComponent implements OnInit {
  displayedColumns: string[] = ['id', 'project_name', 'project_owner', 'contractor', 'status'];
  dataSource = new MatTableDataSource<ParsedProject>();
  projects: Array<Project> = [];
  hasAccess: boolean = false;

  filterForm = new FormGroup({
    search: new FormControl(''),
    project_owner: new FormControl('all'),
    contractor: new FormControl(-1),
    status: new FormControl('all'),
  });

  projectOwnerFilterOptions: ProjectOwnerFilterOptions;
  fuse: Fuse<Project>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private amcProjectService: AmcProjectService,
    public dialog: MatDialog,
    private router: Router,
    private userProfile: UserProfileService
  ) {
    this.filterForm.valueChanges.subscribe(() => this._filterSearch());
    this.fuse = new Fuse(this.amcProjectService.projects.sort(sortProjectsByEndDate), fuseOptions);
  }

  ngOnInit(): void {
    this.hasAccess = this.userProfile.hasAccess();
    if (
      this.hasAccess &&
      this.amcProjectService.projects.length === 0 &&
      this.amcProjectService.contractors.length === 0
    ) {
      Promise.all([
        this.amcProjectService.fetchProjects(),
        this.amcProjectService.fetchContractors(),
      ]).then(() => {
        this.refreshProjectData();
      });
    } else if (this.hasAccess) {
      this.refreshProjectData();
    } else {
      this.userProfile.openSnackBar(
        "You don't have sufficient permissions to create or view projects."
      );
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  refreshProjectData() {
    this.setProjectOwnerFilterOptions();
    this._filterSearch();
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, {
      width: '900px',
      maxHeight: '95vh',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { mode: 'create' },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res?.success) {
        this.amcProjectService.fetchProjects().then(() => {
          this.refreshProjectData();
        });
      }
    });
  }

  _filterSearch() {
    const { search: searchQuery, contractor, status, project_owner } = this.filterForm.value;
    let result: Array<Project>;

    if (!searchQuery || searchQuery === '') {
      result = this.amcProjectService.getProjects();
    } else {
      result = this.fuse.search(searchQuery).map(({ item }) => item);
    }

    if (project_owner !== 'all') {
      result = result.filter(
        item => item.project_owner.toLowerCase().split(' ').join('-') === project_owner
      );
    }

    if (contractor !== -1) {
      result = result.filter(
        item =>
          item.contractor_org && item.contractor_org.id && item.contractor_org.id === contractor
      );
    }

    if (status !== 'all') {
      result = result.filter(item => item.amc_status === status);
    }

    this.projects = result.sort(sortProjectsByEndDate);
    this.updateTableData();
  }

  get status() {
    return this.filterForm.get('status');
  }

  get contractors() {
    return this.amcProjectService.contractors;
  }

  updateTableData() {
    const projectInView = this.getProjectsInView();
    this.dataSource = new MatTableDataSource<ParsedProject>(projectInView);
    this.dataSource.paginator = this.paginator;
  }

  getProjectsInView() {
    return this.projects.map(item => {
      return {
        id: item.id,
        projectName: item.project_name,
        projectOwner: item.project_owner,
        contractor: item.contractor_org,
        status: item.amc_status,
      };
    });
  }

  setProjectOwnerFilterOptions() {
    this.projectOwnerFilterOptions = [
      ...new Set(this.amcProjectService.projects.map(({ project_owner }) => project_owner)),
    ].map(item => {
      return {
        name: item,
        value: item.toLowerCase().split(' ').join('-'),
      };
    });
  }

  openAmcDetails(amcId: string) {
    this.router.navigate(['/business/' + amcId]);
  }
}
