<mat-dialog-content class="!px-2 !py-4 sm:!p-8">
  <h1
    *ngIf="data.mode === 'create'"
    (click)="onDebug()"
    class="m-5 text-base font-medium text-primary sm:text-xl"
  >
    Add Project
  </h1>
  <h1
    (click)="onDebug()"
    *ngIf="data.mode === 'edit'"
    class="m-5 text-base font-medium text-primary sm:text-xl"
  >
    Edit Project
  </h1>

  <h1
    (click)="onDebug()"
    *ngIf="data.mode === 'view'"
    class="m-5 text-base font-medium text-primary sm:text-xl"
  >
    More Project Details
  </h1>
  <mat-stepper class="no-header" linear #stepper>
    <mat-step [stepControl]="createProjectForm_1">
      <h2 class="form-group-title">Project Details</h2>
      <form [formGroup]="createProjectForm_1" (ngSubmit)="onSubmit()">
        <div class="inline-input">
          <label>Project Owner<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              [readonly]="data.mode === 'view'"
              type="text"
              formControlName="project_owner"
            />
          </mat-form-field>
        </div>

        <h3 class="form-group-title-2">Owner's Contact Details</h3>

        <div formGroupName="owner_contact_details">
          <div class="inline-input pl-10">
            <label>Phone Number<sup>*</sup></label>
            <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
              <span matPrefix class="pl-3">+91</span>
              <input
                [readonly]="data.mode === 'view'"
                matInput
                type="tel"
                maxlength="10"
                formControlName="phone_number"
              />
            </mat-form-field>
          </div>

          <div class="inline-input pl-10">
            <label>Email ID<sup>*</sup></label>
            <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
              <input
                [readonly]="data.mode === 'view'"
                matInput
                type="text"
                formControlName="email"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="inline-input">
          <label>Project Name<sup>*</sup></label>

          <mat-form-field class="mb-0 w-full" appearance="outline" subscriptSizing="dynamic">
            <input
              [readonly]="data.mode === 'view' || isProjectNameSameAsOwner"
              matInput
              type="text"
              formControlName="project_name"
            />
          </mat-form-field>
        </div>
        <div class="mb-8 ml-auto w-80">
          <mat-checkbox class="text-sm" formControlName="isProjectNameSameAsOwner"
            >Same as Project Owner</mat-checkbox
          >
        </div>

        <div class="inline-input">
          <label>Site Name<sup>*</sup></label>
          <mat-form-field
            class="w-full"
            appearance="outline"
            formControlName="isProjectNameSameAsOwner"
            subscriptSizing="dynamic"
          >
            <input
              [readonly]="data.mode === 'view'"
              matInput
              type="text"
              formControlName="site_name"
            />
          </mat-form-field>
        </div>

        <h3 class="form-group-title-2">Site's Contact Details</h3>

        <div formGroupName="site_contact_details">
          <div class="inline-input pl-10">
            <label>Phone Number<sup>*</sup></label>
            <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
              <span matPrefix class="pl-3">+91</span>
              <input
                [readonly]="data.mode === 'view'"
                matInput
                type="tel"
                maxlength="10"
                formControlName="phone_number"
              />
            </mat-form-field>
          </div>

          <div class="inline-input pl-10">
            <label>Email ID<sup>*</sup></label>
            <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
              <input
                [readonly]="data.mode === 'view'"
                matInput
                type="text"
                formControlName="email"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="inline-input">
          <label>Project Size (in kWp)<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
            <input [readonly]="data.mode === 'view'" matInput type="text" formControlName="size" />
          </mat-form-field>
        </div>

        <h2 class="form-group-title">Address Information<sup>*</sup></h2>

        <div formGroupName="location">
          <mat-form-field
            class="mb-4 w-full max-w-xs"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input
              placeholder="Pincode"
              matInput
              type="text"
              maxlength="6"
              [readonly]="data.mode === 'view'"
              formControlName="pincode"
            />
          </mat-form-field>

          <div *ngIf="this.pincode?.valid" class="mb-4 flex space-x-5">
            <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
              <input
                [readonly]="true"
                placeholder="City"
                matInput
                type="text"
                formControlName="city"
              />
            </mat-form-field>

            <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
              <input
                [readonly]="true"
                placeholder="State"
                matInput
                type="text"
                formControlName="state"
              />
            </mat-form-field>
          </div>

          <mat-form-field class="mb-4 w-full" appearance="outline" subscriptSizing="dynamic">
            <textarea
              placeholder="Enter Full Address (Area and Street)"
              matInput
              [readonly]="data.mode === 'view'"
              formControlName="full_address"
            >
            </textarea>
          </mat-form-field>
        </div>

        <div *ngIf="this.pincode?.valid" class="inline-input">
          <label>DISCOM<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
            <input
              [readonly]="data.mode === 'view'"
              matInput
              type="text"
              formControlName="discom"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let item of discom" [value]="item">{{ item }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label>Grid Connection Type<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
            <mat-select formControlName="grid_connection_type">
              <mat-option *ngFor="let offering of OfferingType" [value]="offering">{{
                offering.split('-').join(' ')
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label>Phase Type<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
            <mat-select formControlName="phase_type">
              <mat-option *ngFor="let phase of phaseType" [value]="phase">{{ phase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label>Connection Type<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
            <mat-select formControlName="connection_type">
              <mat-option *ngFor="let connection of connectionType" [value]="connection">{{
                connection
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h2 class="form-group-title">Block</h2>

        <div formArrayName="blocks">
          <div formGroupName="{{ i }}" *ngFor="let block of blocks.controls; let i = index">
            <h3 class="mt-4 w-24 font-medium">Block {{ i + 1 }}<sup>*</sup> :</h3>
            <div class="inline-input">
              <label>Name</label>
              <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                <input
                  [readonly]="data.mode === 'view'"
                  matInput
                  type="text"
                  formControlName="block_name"
                />
              </mat-form-field>
            </div>

            <div class="inline-input">
              <label for="add-project-mounting-type">Mounting Type</label>
              <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                <mat-select id="add-project-mounting-type" formControlName="mounting_type">
                  <mat-option *ngFor="let mount of mountingType" [value]="mount">{{
                    mount
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="data.mode === 'view'" class="mb-8 mt-3 w-full"></div>

        <div *ngIf="data.mode !== 'view'" class="mb-8 mt-3 flex justify-center space-x-8">
          <button
            (click)="addFormArray('blocks')"
            type="button"
            class="mr-2 inline-flex items-center rounded-full bg-lima-400 p-2.5 text-center text-sm font-medium text-white hover:bg-lima-500 focus:outline-none focus:ring-4 focus:ring-lima-300"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="blocks.controls.length > 1"
            (click)="removeFormArray('blocks')"
            type="button"
            class="mr-2 inline-flex items-center rounded-full bg-red-400 p-2.5 text-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>

        <h2 class="form-group-title">Panels Details<sup>*</sup></h2>

        <div formArrayName="panels">
          <div formGroupName="{{ i }}" *ngFor="let panel of panels.controls; let i = index">
            <div class="grid grid-cols-3 gap-3 sm:grid-cols-4">
              <div>
                <label class="text-sm sm:text-base">Panel Make</label>
                <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                  <input
                    [readonly]="data.mode === 'view'"
                    matInput
                    type="text"
                    formControlName="make"
                  />
                </mat-form-field>
              </div>
              <div>
                <label class="text-sm sm:text-base">Wp / Panel</label>
                <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                  <input
                    [readonly]="data.mode === 'view'"
                    matInput
                    type="number"
                    formControlName="wp_per_panel"
                  />
                </mat-form-field>
              </div>
              <div>
                <label class="text-sm sm:text-base">No. of Panels</label>
                <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                  <input
                    [readonly]="data.mode === 'view'"
                    matInput
                    type="number"
                    formControlName="no_of_panels"
                  />
                </mat-form-field>
              </div>
              <div class="col-span-3 flex w-full flex-col items-center sm:col-auto">
                <div class="text-sm sm:text-base">Total Wp</div>
                <div class="flex h-[56px] items-center justify-center">
                  <span class="mr-5">=</span>
                  <span>{{ getTotalWp(i) }}</span>
                </div>
              </div>
            </div>

            <div class="mb-2 w-full">
              <mat-error *ngIf="isTotalWpWrong(i)"
                >Error: The Total Wp is much greater than your Project Size. Please enter correct
                panel details.</mat-error
              >
            </div>
          </div>
        </div>

        <div *ngIf="data.mode === 'view'" class="mb-8 mt-3 w-full"></div>

        <div *ngIf="data.mode !== 'view'" class="my-8 flex justify-center space-x-8">
          <button
            (click)="addFormArray('panels')"
            type="button"
            class="mr-2 inline-flex items-center rounded-full bg-lima-400 p-2.5 text-center text-sm font-medium text-white hover:bg-lima-500 focus:outline-none focus:ring-4 focus:ring-lima-300"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="panels.controls.length > 1"
            (click)="removeFormArray('panels')"
            type="button"
            class="mr-2 inline-flex items-center rounded-full bg-red-400 p-2.5 text-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>

        <div formArrayName="inverters">
          <div
            formGroupName="{{ i }}"
            *ngFor="let inverter of inverters.controls; let i = index"
            class="grid grid-cols-3 gap-3"
          >
            <div>
              <label for="" class="text-sm sm:text-base">Inverter Company</label>
              <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                <input
                  [readonly]="data.mode === 'view'"
                  id="add-project-size"
                  matInput
                  type="text"
                  formControlName="name"
                />
              </mat-form-field>
            </div>
            <div>
              <label for="" class="text-sm sm:text-base">Size (in kWp)</label>
              <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                <input
                  [readonly]="data.mode === 'view'"
                  id="add-project-size"
                  matInput
                  type="number"
                  formControlName="size"
                />
              </mat-form-field>
            </div>
            <div>
              <label for="add-project-inverter-size" class="text-sm sm:text-base"
                >No. of Inverters</label
              >
              <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
                <input
                  id="add-project-inverter-size"
                  matInput
                  [readonly]="data.mode === 'view'"
                  type="number"
                  formControlName="no_of_inverters"
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="data.mode === 'view'" class="mb-8 mt-3 w-full"></div>

        <div *ngIf="data.mode !== 'view'" class="my-8 flex justify-center space-x-8">
          <button
            (click)="addFormArray('inverters')"
            type="button"
            class="mr-2 inline-flex items-center rounded-full bg-lima-400 p-2.5 text-center text-sm font-medium text-white hover:bg-lima-500 focus:outline-none focus:ring-4 focus:ring-lima-300"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="inverters.controls.length > 1"
            (click)="removeFormArray('inverters')"
            type="button"
            class="mr-2 inline-flex items-center rounded-full bg-red-400 p-2.5 text-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>

        <div class="inline-input">
          <label for="add-project-size">Commissioning Date<sup>*</sup></label>
          <mat-form-field class="w-full" appearance="outline">
            <input
              [readonly]="data.mode === 'view'"
              id="add-project-size"
              matInput
              formControlName="commissioning_date"
              [matDatepicker]="commissioningDatePicker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="commissioningDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #commissioningDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>

      <div class="stepper-nav-container">
        <button
          [disabled]="data.mode !== 'view' && !createProjectForm_1.valid"
          matStepperNext
          type="button"
          class="next-btn"
        >
          Next
          <mat-icon>navigate_next</mat-icon>
        </button>

        <button
          [disabled]="isLoading || !isValidtoSave()"
          *ngIf="data.mode === 'create'"
          (click)="saveAndClose()"
          type="button"
          class="back-btn"
        >
          <mat-icon class="mr-1">save</mat-icon>
          Save for later
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="createProjectForm_2">
      <h2 class="form-group-title">AMC Contact Details</h2>
      <form [formGroup]="createProjectForm_2" (ngSubmit)="onSubmit()">
        <div class="inline-input">
          <label>Amc Tenure</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-date-range-input [rangePicker]="amcTenureDatePicker">
              <input
                [readonly]="data.mode === 'view'"
                formControlName="start_date"
                matStartDate
                placeholder="Start Date"
              />
              <input
                [readonly]="data.mode === 'view'"
                formControlName="end_date"
                matEndDate
                placeholder="End Date"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="amcTenureDatePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #amcTenureDatePicker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label>AMC Type</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-select formControlName="amc_contract_type">
              <mat-option *ngFor="let amcType of amcTypes" [value]="amcType">{{
                amcType
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <h3
          *ngIf="
            data.mode !== 'view' &&
            amc_contract_type?.value &&
            (amc_contract_type.value == 'Preventive with cleaning' ||
              amc_contract_type.value == 'Comprehensive with cleaning')
          "
          class="form-group-title-2"
        >
          No. of Visits
        </h3>

        <div *ngIf="data.mode !== 'view' && amc_contract_type.value" formArrayName="activities">
          <div
            formGroupName="{{ i }}"
            *ngFor="let activity of amc_activities.controls; let i = index"
          >
            <div
              *ngIf="
                amc_contract_type.value == 'Preventive with cleaning' ||
                amc_contract_type.value == 'Comprehensive with cleaning'
              "
            >
              <div class="inline-input pl-10">
                <label>{{ i == 0 ? 'For AMC' : 'For Cleaning Visit' }}</label>
                <mat-form-field class="w-full" appearance="outline">
                  <input matInput type="number" [min]="1" formControlName="visits" />
                </mat-form-field>
              </div>
            </div>
            <div
              *ngIf="
                !(
                  amc_contract_type.value == 'Preventive with cleaning' ||
                  amc_contract_type.value == 'Comprehensive with cleaning'
                )
              "
            >
              <div class="inline-input">
                <label> No. of Visits</label>
                <mat-form-field class="w-full" appearance="outline">
                  <input matInput type="number" [min]="1" formControlName="visits" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="inline-input">
          <label>AMC Fee from Customer</label>
          <mat-form-field class="w-full" appearance="outline">
            <span matPrefix class="pl-3">₹</span>
            <input
              [readonly]="data.mode === 'view'"
              matInput
              type="number"
              formControlName="amc_fee"
            />
            <span matSuffix class="mr-3">+ GST</span>
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label>Contractor</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-select formControlName="contractor_org">
              <mat-option *ngFor="let contactor of contractors" [value]="contactor.id">{{
                contactor.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="inline-input">
          <label>Cost of Service</label>
          <mat-form-field class="w-full" appearance="outline">
            <span matPrefix class="pl-3">₹</span>
            <input
              [readonly]="data.mode === 'view'"
              matInput
              type="number"
              formControlName="cost_of_service"
            />
            <span matSuffix class="mr-3">+ GST</span>
          </mat-form-field>
        </div>
      </form>

      <div class="stepper-nav-container">
        <button matStepperPrevious type="button" class="back-btn">
          <mat-icon>navigate_before</mat-icon>
          Previous
        </button>

        <button
          *ngIf="data.mode !== 'view'"
          (click)="createProject()"
          [disabled]="!createProjectForm_2.valid || isLoading"
          type="button"
          class="save-btn"
        >
          Save & Close
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
